<template>
    <div class="vx-row mb-12">
        <div v-bind:class="[
            detail ? detailShow + ' vertical-divider' : '',
            detailHide,
        ]">
            <div class="vx-row mb-12">
                
            </div>
            <data-table :responseData="responseData" :propsParams="params" :header="header"
                @reloadDataFromChild="reloadData">
                <template slot="thead">
                    <th width="7%">
                        Action
                    </th>
                </template>
                <template slot="tbody">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.records" :class="tr.class">
                        <vs-td class="whitespace-no-wrap">
                            <template>
                                <div class="mt-4 flex justify-between">
                                    <vx-tooltip text="Show" class="mr-4">
                                        <vs-button type="line" icon-pack="feather" color="green" icon="icon-eye"
                                            v-on:click.stop="
                                                handleShow(tr)" />
                                    </vx-tooltip>
                                    <vx-tooltip text="Print Invoice" class="mr-4">
                                        <vs-button
                                            type="line"
                                            color="blue"
                                            icon-pack="feather"
                                            icon="icon-file"
                                            v-on:click.stop="doDownloadPdf(tr)"
                                        />
                                    </vx-tooltip>
                                </div>
                            </template>

                        </vs-td>                        
                        <vs-td>
                            {{ tr.Code }}
                        </vs-td>
                        <vs-td>
                            {{ tr.OperatingUnitCode + " - " + tr.OperatingUnitName }}
                        </vs-td>
                        <vs-td>
                            <span v-if="tr.TransactionType == '01'"> AR Invoice </span>
                            <span v-else-if="tr.TransactionType == '02'"> Customer Deposit </span>
                            <span v-else-if="tr.TransactionType == '03'"> Credit Note </span>
                            <span v-else> - </span>
                        </vs-td>
                        <vs-td>
                            {{ tr.Note }}
                        </vs-td>
                        <vs-td>
                            {{ dateFormat(tr.CreatedAt) }}<br>
                        </vs-td>
                    </vs-tr>
                </template>
            </data-table>

            <div>
                <div>
                    <vs-prompt color="primary" title="Confirmation" @cancel="activePromptConfrm = false" @accept="doDelete"
                        @close="closePrompt" :active.sync="activePromptConfrm">
                        <div class="con-exemple-prompt">
                            Are you sure to delete ?
                            <br />
                            <br />
                        </div>
                    </vs-prompt>
                    <vs-prompt color="primary" title="Confirmation" @cancel="submitPromptConfirm = false" @accept="doSubmit"
                        @close="closePrompt" :active.sync="submitPromptConfirm">
                        <div class="con-exemple-prompt">
                            Are you sure to submit write-off ?
                            <br />
                            <br />
                        </div>
                    </vs-prompt>
                </div>
            </div>
            <!-- Approve Prompt -->

        </div>
        <!-- form -->
        <transition name="detail-fade">
            <div v-if="detail" v-bind:class="[
                detail ? 'vx-col md:w-5/6 w-full  mb-base' : '',
                detailHide,
            ]">
                <vs-row>
                    <vs-col style="padding-bottom: 8px" vs-offset="8" vs-type="flex" vs-justify="rigth" vs-align="rigth"
                        vs-w="4">
                        <vs-button class="ml-auto" size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                            icon="icon-x-square">Close</vs-button>
                    </vs-col>
                    <vs-col vs-offset="0" vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                        <vs-button size="small" v-on:click="handleClose" color="grey" icon-pack="feather"
                            icon="icon-x-square"></vs-button>
                    </vs-col>
                </vs-row>
            </div>
        </transition>
    </div>
</template>
<script lang="ts">
import moment from "moment"
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import logoSDN from "../../../../assets/images/logo/logo-sdn.png";

// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
    components: {
        
    },
    props: {
        selected: Object,
        option: Object,
    },
    data() {
        return {
            params: {
                search: "",
                length: 10,
                page: 1,
                order: "id",
                sort: "desc",
            },
            header: [
                {
                    text: "Code",
                    value: "code",
                    // width: '5%'
                },
                {
                    text: "Operating Unit",
                    value: "oeprating_unit",
                    // width: '5%'
                },
                {
                    text: "Transaction Type",
                    value: "transaction_type",
                    // width: '5%'
                },
                {
                    text: "Note",
                    value: "note",
                },
                {
                    text: "Created Date",
                    value: "created_at",
                }
            ],
            methods: ['', 'Cash', 'Giro', 'Cheque', 'Transfer'],
            responseData: {},
            detailShow: "vx-col md:w-1/6 w-full mb-base",
            detailHide: "core vx-col md:w-1/1 w-full mb-base",
            detail: false,
            notes: "",
            activePromptConfrm: false,
            submitPromptConfirm: false,
            approveOrReject: false,
            approvalPrompt: false,
            rejectPrompt: false,
            selectedData: {},
        };
    },
    computed: {},
    watch: {},
    mounted() {
        // this.reloadData(this.params);

        // this.getData();
        // this.page(1);
        // this.dataId = this.data[0].id
    },
    methods: {
        closePrompt() {
            this.activePromptConfrm = false
            this.submitPromptConfirm = false
        },
        dateFormat(value) {
            return moment.utc(String(value)).format("DD/MM/YYYY");
        },
        doDelete() {
            this.$vs.loading();
            this.$http.delete('/api/v1/write-off/' + this.selectedData.ID)
                .then((r) => {
                    if (r.code == 500) {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else if (r.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                        // this.$router.push("/finance/write-off");
                        this.reloadData(this.params)
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                    }
                });
        },
        doSubmit() {
            this.$vs.loading();
            this.$http.post('/api/v1/write-off/submit' + this.selectedData.ID)
                .then((r) => {
                    if (r.code == 500) {
                        this.$vs.loading.close();
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    } else if (r.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                        // this.$router.push("/finance/write-off");
                        this.reloadData(this.params)
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: r.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                        this.$vs.loading.close();
                    }
                });
        },
        handleApprove(selected) {
            this.selectedData.class = ""
            this.approveOrReject = 1
            selected.class = "highlight"
            this.selectedData = selected
            this.rejectPrompt = false
            this.approvalPrompt = true
            // console.log(this.selectedData)
        },
        handleReject(selected) {
            this.selectedData.class = ""
            this.approveOrReject = 0
            selected.class = "highlight"
            this.selectedData = selected
            this.approvalPrompt = false
            this.rejectPrompt = true
        },

        handleDelete(selected) {
            this.selectedData = selected
            this.activePromptConfrm = true
        },
        handleSubmit(selected) {
            this.selectedData = selected
            this.activePromptConfrm = true
        },
        closeDetail() {
            this.activePrompt2 = false;
            this.detail = false;
            this.selectedData.class = ""
            this.selectedData = {
                ID: 0,
            };
            this.reloadData(this.params)
        },
        dataTable(params) {
            return new Promise((resolve, reject) => {
                this.$http.get('/api/v1/write-off/data-table', {
                    params: {
                        status: 1,
                        is_view: 1,
                        search: params.search,
                        length: params.length,
                        page: params.page,
                        order: params.sort,
                        sort: params.order,
                    }
                }).then((r) => {
                    resolve(r)
                }).catch((e) => {
                    reject(e)
                })
            })
        },
        create() {
            this.$router.push("/finance/write-off/create");

        },
        handleShow(selected) {
            this.$router.push({
                name: 'find-write-off-confirm',
                // preserve current path and remove the first char to avoid the target URL starting with `//`
                params: { id: selected.ID },
                // preserve existing query and hash if any

            })
        },
        handleClose() {
            this.reloadData(this.params)
            this.selectedData.class = ""
            this.detail = false
            this.selectedData = {
                ID: 0,
            };
        },
        formatPrice(angka, prefix = "") {
            return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        reloadData(params) {
            this.params = params;
            // console.log(params);
            this.$vs.loading();

            const dataTable = this.dataTable(params);
            dataTable.then((r) => {
                // console.log(r);
                if (r.code == 500) {
                    this.$vs.loading.close();
                } else if (r.code == 200) {
                    this.$vs.loading.close();
                    this.data = r.data.records;
                    this.responseData = r.data;
                    this.responseData.length = r.data.length;
                    // this.checkedAll = false;
                } else {
                    this.$vs.loading.close();
                }
            });
        },
        doByPassDownloadPdf() {
            let selected = this.selectedData;
            this.$vs.loading();
            // console.log(this.$userLogin.company_logo);
            this.$http.get('/api/v1/write-off/pdf/' + selected.ID).then((result) => {
                // console.log("RESULT" , result);
                let writeOff = result.data.writeOff;
                let writeOffLines = result.data.writeOffLines;

                /* 
                    Pengecekan Layout apakah data Write Off termasuk Allowance&Expense, atau Allowance, atau Expense saja
                */
                
                let expense = 0, provision = 0
                writeOffLines.forEach(wl => {
                    provision += parseInt(wl.Provision)
                    expense += parseInt(wl.Expense)
                });

                // console.log("provision", provision)
                // console.log("expense", expense)
                // provision = 1, expense = 1 // mixed
                // provision = 1, expense = 0 // allowance
                // provision = 0, expense = 1 // expense

                if (expense > 0 && provision > 0) {
                    // console.log("Layout pdf allowance & expense")
                    this.printAllowanceExpense(selected, writeOff, writeOffLines);
                } else if (expense == 0 && provision > 0) {
                    // console.log("Layout pdf allowance")
                    this.printAllowance(selected, writeOff, writeOffLines);
                } else if (expense > 0 && provision == 0) {
                    // console.log("Layout pdf expense")
                    this.printExpense(selected, writeOff, writeOffLines);
                }
        
        
        //BATAS FORMAT DOKUMEN
        //   this.$http
        //     .post(
        //     //   "/api/v1/purchase-payment-request/print/" + selected.ID,
        //       "/api/v1/write-off/print/" + selected.ID,
        //       params
        //     )
        //     .then((r) => {
        //       this.reloadData(this.params);
        //     })
        //     .catch((e) => {
        //       // this.getData();
        //       this.reloadData(this.params);
        //     });
          //console.log(result)
          this.$vs.loading.close();
        })
        // .catch((e) => {
        //   this.$vs.loading.close();
        // });
        },
        doDownloadPdf(selected) {
            this.selectedData = selected;
            // console.log("DISINI")
            // console.log("selected data" , this.selectedData)
            this.doByPassDownloadPdf(selected);
        },
        printAllowanceExpense(selected, writeOff, writeOffLines){
            // Print layout allowance & expense
            const createdAt = this.dateFormat(writeOff.CreatedAt);
            let date = moment().format("DD-MM-YYYY");
            const doc = new jsPDF('l', 'mm', [297, 210]);
            let fontSize = 12, finalY = 0;
            doc.setFont("helvetica").setFontSize(fontSize);
            doc.addImage(logoSDN, "PNG", 20, 5, 50, 20);
            doc.text("BERITA ACARA PERSETUJUAN PENGHAPUSAN PIUTANG TAK TERTAGIH", 145, 15, { align: "center" }); //(text,x,y)
            fontSize = 10;
            doc.setFont("helvetica").setFontSize(fontSize);          
          
            const head = [
                [ 
                    "Cabang", 
                    "Cust. Account", 
                    "Customer Name",
                    "Doc. Date",
                    "Doc. Type",
                    "Doc. No", 
                    "Assignment", 
                    "Billing Retur", 
                    "Nilai Yang Dihapuskan", 
                    "Keterangan"
                ],
            ];

            let line = [];

            let total = 0;
            // for (let q = 0; q < 5; q++) {
            writeOffLines.map((v, i) => {
                // console.log(v)
                line.push([
                    writeOff.TerritoryCode,
                    v.PersonCode,
                    v.PersonName,
                    this.dateFormat(v.ReferenceDate),
                    "",
                    v.ReferenceCode,
                    v.Assignment,
                    "",
                    v.RemainingValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    writeOff.Note
                ]);

                total += v.RemainingValue;
                // console.log("total", total)
            
                this.total = total;
            });
            // }

            line.push([
                { content: "Total ", colSpan: 8 },
                {
                    content: total.toFixed(2).toString().
                    replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    align: "right",
                },
            ]);  
        
            autoTable(doc, {
                startY: 30,
                head: head,
                body: line,
                margin: {right:10,left:10},
                theme: "grid",
                columnStyles: {
                    0: {
                        fontSize: 8,
                        cellWidth: 18
                    },
                    1: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 28
                    },
                    2: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 45
                    },
                    3: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 20
                    },
                    4: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 20
                    },
                    5: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 25
                    },
                    6: {
                        halign: 'right',
                        fontSize: 8,
                        cellWidth: 25
                    },
                    7: {
                        halign: 'right',
                        fontSize: 8,
                        cellWidth: 21
                    },
                    8: {
                        halign: 'right',
                        fontSize: 8,
                        cellWidth: 27
                    },
                    9: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 50
                    }
                }
            });

            finalY = 10 + doc.lastAutoTable.finalY;
            
            doc.text("Diajukan Oleh", 40, finalY); //(text,x,y)
            doc.text("(\t\t\t\t\t)", 32, (finalY + 25)); //(text,x,y)

            doc.text("Diketahui Oleh", 130, finalY); //(text,x,y)
            doc.text("(\t\t\t\t\t\t\t)", 115, (finalY + 25)); //(text,x,y)

            doc.text("Disetujui Oleh", 232, finalY); //(text,x,y)
            doc.text("(\t\t\t\t\t\t)", 221, (finalY + 25)); //(text,x,y)

            doc.save(
                "Write-Off-AllowanceExpense-" + selected.Code + ".pdf"
            );
        },
        printAllowance(selected, writeOff, writeOffLines){
            // Print layout allowance & expense
            const createdAt = this.dateFormat(writeOff.CreatedAt);
            let date = moment().format("DD-MM-YYYY");
            const doc = new jsPDF('l', 'mm', [297, 210]);
            let fontSize = 12, finalY = 0;
            doc.setFont("helvetica").setFontSize(fontSize);
            doc.addImage(logoSDN, "PNG", 20, 5, 50, 20);
            doc.text("Berita Acara Persetujuan Penghapusan Piutang Negative", 145, 15, { align: "center" }); //(text,x,y)
            fontSize = 10;
            doc.setFont("helvetica").setFontSize(fontSize);          
          
            const head = [
                [ 
                    "ID Customer", 
                    "Nama Customer",
                    "Document. No", 
                    "Document. Date",
                    "Nominal", 
                    "Keterangan"
                ],
            ];

            let line = [];

            let total = 0;
            // for (let q = 0; q < 5; q++) {
            writeOffLines.map((v, i) => {
                line.push([
                    v.PersonCode,
                    v.PersonName,
                    v.ReferenceCode,
                    this.dateFormat(v.ReferenceDate),
                    v.RemainingValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    writeOff.Note
                ]);

                total += v.RemainingValue;
            
                this.total = total;
            });
            // }
            
            line.push([
                { content: "Total ", colSpan: 4 },
                {
                    content: total.toFixed(2).toString().
                    replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    halign: "right",
                },
            ]);  
        
            autoTable(doc, {
                startY: 30,
                head: head,
                body: line,
                margin: {right:10,left:10},
                theme: "grid",
                columnStyles: {
                    0: {
                        fontSize: 8,
                        cellWidth: 37
                    },
                    1: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 40
                    },
                    2: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 45
                    },
                    3: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 40
                    },
                    4: {
                        halign: 'right',
                        fontSize: 8,
                        cellWidth: 30
                    },
                    5: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 80
                    },
                }
            });

            finalY = 5 + doc.lastAutoTable.finalY;
            // finalY = autoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
            // Autotable 2, layout kolom catatan dibawah
            autoTable(doc, {
                startY: finalY,
                body: [[{ content: "Note : ", styles: {minCellHeight: 20}}]],
                margin: {right:10,left:10},
                theme: "grid",
                columnStyles: {
                    0: {
                        fontSize: 8,
                        cellWidth: 272
                    },
                }
            });

            finalY = 10 + doc.lastAutoTable.finalY;

            doc.text("Diajukan Oleh", 40, finalY); //(text,x,y)
            doc.text("(\t\t\t\t\t)", 32, (finalY + 25)); //(text,x,y)

            doc.text("Disetujui Oleh", 130, finalY); //(text,x,y)
            doc.text("(\t\t\t\t\t\t\t)", 115, ((finalY + 25))); //(text,x,y)

            doc.text("Diketahui Oleh", 232, finalY); //(text,x,y)
            doc.text("(\t\t\t\t\t\t)", 221, (finalY + 25)); //(text,x,y)

            doc.save(
                "Write-Off-Allowance-" + selected.Code + ".pdf"
            );
        },
        printExpense(selected, writeOff, writeOffLines){
            // Print layout allowance & expense
            const createdAt = this.dateFormat(writeOff.CreatedAt);
            let date = moment().format("DD-MM-YYYY");
            const doc = new jsPDF('l', 'mm', [297, 210]);
            let fontSize = 12, finalY = 0;
            doc.setFont("helvetica").setFontSize(fontSize);
            doc.addImage(logoSDN, "PNG", 20, 5, 50, 20);
            doc.text("BERITA ACARA PERSETUJUAN PENGHAPUSAN PIUTANG NEGATIVE", 145, 15, { align: "center" }); //(text,x,y)
            fontSize = 10;
            doc.setFont("helvetica").setFontSize(fontSize);          
          
            const head = [
                [ 
                    "ID Customer", 
                    "Nama Customer",
                    "Document. No", 
                    "Document. Date",
                    "Overdue Date", 
                    "Nominal", 
                    "Keterangan"
                ],
            ];

            let line = [];

            let total = 0;
            // for (let q = 0; q < 5; q++) {
            writeOffLines.map((v, i) => {
                line.push([
                    // writeOff.TerritoryCode,
                    v.PersonCode,
                    v.PersonName,
                    v.ReferenceCode,
                    this.dateFormat(v.ReferenceDate),
                    v.Aging,
                    v.RemainingValue.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    writeOff.Note
                ]);

                total += v.RemainingValue;
                // console.log("total", total)
                this.total = total;
            });
            // }

            line.push([
                { content: "Total ", colSpan: 5 },
                {
                    content: total.toFixed(2).toString().
                    replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    halign: "right",
                },
            ]);  
        
            autoTable(doc, {
                startY: 30,
                head: head,
                body: line,
                margin: {right:10,left:10},
                theme: "grid",
                columnStyles: {
                    0: {
                        fontSize: 8,
                        cellWidth: 37
                    },
                    1: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 40
                    },
                    2: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 40
                    },
                    3: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 40
                    },
                    4: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 30
                    },
                    5: {
                        halign: 'right',
                        fontSize: 8,
                        cellWidth: 30
                    },
                    6: {
                        halign: 'left',
                        fontSize: 8,
                        cellWidth: 55
                    },
                }
            });

            finalY = 5 + doc.lastAutoTable.finalY;
            // finalY = autoTable.finalY; //this gives you the value of the end-y-axis-position of the previous autotable.
            // Autotable 2, layout kolom catatan dibawah
            autoTable(doc, {
                startY: finalY,
                body: [[{ content: "Note : ", styles: {minCellHeight: 20}}]],
                margin: {right:10,left:10},
                theme: "grid",
                columnStyles: {
                    0: {
                        fontSize: 8,
                        cellWidth: 272
                    },
                }
            });

            finalY = 10 + doc.lastAutoTable.finalY;
            
            doc.text("Diajukan Oleh", 30, finalY); //(text,x,y)
            doc.text("(\t\t\t\t\t)", 22, (finalY + 25)); //(text,x,y)

            doc.text("Diperiksa Oleh", 90, finalY); //(text,x,y)
            doc.text("(\t\t\t\t\t\t\t)", 75, (finalY + 25)); //(text,x,y)
            
            doc.text("Diketahui Oleh", 170, finalY); //(text,x,y)
            doc.text("(\t\t\t\t\t\t\t)", 155, (finalY + 25)); //(text,x,y)

            doc.text("Disetujui Oleh", 232, finalY); //(text,x,y)
            doc.text("(\t\t\t\t\t\t)", 221, (finalY + 25)); //(text,x,y)

            doc.save(
                "Write-Off-Expense-" + selected.Code + ".pdf"
            );
        }
    },
};
</script>
<style scoped>
.nonfixed {
    position: inherit;
    padding-left: 20px;
}

.core-enter-active {
    transition: all 0.3s ease;
}

.core-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.core {
    -webkit-transform-origin-y: all 1s ease;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
    font-size: 12px;
}

.vertical-divider {
    border-right: 1px solid #7367f0;
    /* min-height: 800px; */
    /* height: 100%; */
    -webkit-mask-position-y: fixed;
    /* padding: 5px; */
}

.vs-con-table.stripe .tr-values:nth-child(2n) {
    background: beige;
}

.highlight>td {
    background-color: #d0cdf0;
}

.colored {
    border: 1px solid #7367f0;
    position: fixed;
    left: 4%;
    top: 40%;
    max-width: 45%;
    z-index: 999999999999;
    background: antiquewhite;
    background-color: antiquewhite;
}

.vs-dialog {
    max-width: 1000px;
}
</style>